import './underscore.js'

/** @format */
jQuery(function ($) {
  // Main navigation toggle
  var scrollPosition = $(window).scrollTop()

  var toggleMobileMenu = function () {
    if (!$('html').hasClass('state--nav-open')) {
      scrollPosition = $(window).scrollTop()
    }

    $('html').toggleClass('state--nav-open')
    $('#plays-nav').focus()

    $('html, body').scrollTop(scrollPosition)
    $(this).attr('aria-expanded', $('html').hasClass('state--nav-open'))

    trapFocus($('#site-navigation'), $('#plays-nav'))
  }

  var trapFocus = function (parents, elementToFocus) {
    const elements = parents.find('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])')
    const firstElement = elements[0]
    const lastElement = elements[elements.length - 1]
    const KEYCODE_TAB = 9

    elementToFocus.focus()

    parents.on('keydown', function (e) {
      var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB)

      if (!isTabPressed) {
        return
      }

      if (e.shiftKey) {
        if (document.activeElement === firstElement) {
          lastElement.focus()
          e.preventDefault()
        }
      } else {
        if (document.activeElement === lastElement) {
          firstElement.focus()
          e.preventDefault()
        }
      }
    })
  }

  $('#c-menu-trigger').on('click', toggleMobileMenu)

  // Esc closes menus and dialogs
  const KEYCODE_ESC = 27
  $(document).keyup(function (e) {
    if (e.keyCode === KEYCODE_ESC) {
      $('html').removeClass('state--nav-open, state--anchors-active')
      $('#c-menu-trigger').attr('aria-expanded', false)
    }
  })

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  // Add sticky scroll class
  $(document).on('scroll', debounce(function() {
    var stickyPosition = 160

    if ($(document).scrollTop() > stickyPosition) {
      $('.c-global-header').addClass('stuck')
      $('body').addClass('state--header-stuck')
    } else {
      $('.c-global-header').removeClass('stuck')
      $('body').removeClass('state--header-stuck')
    }
  }, 15))

  /*
   * Add body class to determine seatmap background
   */
  // Options for the observer (which mutations to observe)
  const config = { attributes: true, childList: true, subtree: true }

  // Callback function to execute when mutations are observed
  const callback = function (mutationsList, observer) {
    // Use traditional 'for loops' for IE 11

    const $body = $('body')

    for (const mutation of mutationsList) {
      // Check for seat ID within each seatmap layout
      if (document.querySelector(".tn-syos-seat-map__seat[data-tn-seat-id=\"19721\"]") && mutation.type === "childList") {
        // If so then add body class, and remove other body classes
        // Wimberly Theatre
        $body.addClass('seatmap--wimberly-theatre-orchestra');
        $body.removeClass('seatmap--wimberly-theatre-mezzanine');
      } else if (document.querySelector(".tn-syos-seat-map__seat[data-tn-seat-id=\"21639\"]") && mutation.type === "childList") {
        $body.addClass('seatmap--wimberly-theatre-mezzanine');
        $body.removeClass('seatmap--wimberly-theatre-orchestra');
      } else if (document.querySelector(".tn-syos-seat-map__seat[data-tn-seat-id=\"164542\"]") && mutation.type === "childList") {
        // Wimberly Theatre Bluest Eye
        $body.addClass('seatmap--wimberly-theatre-bluest-eye-orchestra');
        $body.removeClass('seatmap--wimberly-theatre-bluest-eye-mezzanine');
      } else if (document.querySelector(".tn-syos-seat-map__seat[data-tn-seat-id=\"166146\"]") && mutation.type === "childList") {
        $body.addClass('seatmap--wimberly-theatre-bluest-eye-mezzanine');
        $body.removeClass('seatmap--wimberly-theatre-bluest-eye-orchestra');
      } else if (document.querySelector(".tn-syos-seat-map__seat[data-tn-seat-id=\"157022\"]") && mutation.type === "childList") {
        // Emerson Cutler Majestic 
        $body.addClass('seatmap--emerson-cutler-majestic-orchestra');
        $body.removeClass('seatmap--emerson-cutler-majestic-mezzanine');
      } else if (document.querySelector(".tn-syos-seat-map__seat[data-tn-seat-id=\"158623\"]") && mutation.type === "childList") {
        $body.addClass('seatmap--emerson-cutler-majestic-mezzanine');
        $body.removeClass('seatmap--emerson-cutler-majestic-orchestra');
      }
    }
  }

  function addObserverIfDesiredNodeAvailable () {
    const targetNode = document.querySelector('.tn-syos')
    if (!targetNode) {
      // The node we need does not exist yet.
      // Wait 500ms and try again
      window.setTimeout(addObserverIfDesiredNodeAvailable, 500)
      return
    }
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback)

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config)
  }
  addObserverIfDesiredNodeAvailable()
})
